import "../accueil/Accueil.css";
import "./Ressources.css";
import {useContext, useEffect, useState} from "react";
import {getStrapiData} from "../../request/strapi";
import {ObjectContext} from "../../context/ObjectContext";

const Ressources = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;

  const setFooterColor = useContext(ObjectContext).setFooterColor;

  useEffect(() => {
    setFooterColor("#d2fa94")
    fetchRessourceData();
  }, []);

  const fetchRessourceData = async () => {
    await getStrapiData('ressources-deepblocks').then((response) => {
      setData(response.data);
      setIsLoading(false);
    }).catch((error) => {
      console.error('Erreur lors de la récupération des données:', error);
      setIsLoading(false)
    })
  }

  const ressourceElement = () => {
    if (data === undefined) {
      if (isLoading) {
        return <p>Chargement en cours...</p>
      }
      return <p>Aucune ressources trouvé</p>
    }
    return (
      data.sort((a, b) => a.id - b.id).map((ressource) => {
        return (
          <div className="ressources-content-data" key={ressource.id}>
            <a href={ressource.attributes.link} rel="noreferrer" target="_blank">
              {
                ressource.attributes.picto.data !== null ?
                  <img className="ressource-content-data-img" src={API_URL + ressource.attributes.picto.data.attributes.url} alt={ressource.attributes.name}/>
                  :
                  <img className="ressource-content-data-img" src="https://via.placeholder.com/150" alt="placeholder"/> // Placeholder image
              }
              <h4 className="ressource-content-data-title">{ressource.attributes.title || "Titre indisponible"}</h4>
            </a>
          </div>
        )
      })
    )
  }

  return (
    <div className="ressources-container">
      <section className="ressources-intro">
        <div className="ressources-intro-left">
          <p className="ressources-intro-title" >Retrouvez à cet endroit, tous nos guides intégrateurs et utilisateurs.</p>
          <p className="ressources-intro-description">L'ensemble des liens de téléchargement des packages de nos outils, accompagnés des vidéos et tutoriels.</p>
        </div>
        <div className="ressources-intro-right">
          <img className="ressources-intro-logo" src="/pictoressource.svg" alt=""/>
        </div>
      </section>

      <section className="ressources-guide">
        <div className="section-header">
          <div className="section-header-title">
            <img className="section-header-logo" src="/Carrejaune.svg" alt="puce"/>
            <p className="section-header-name">Nos guides</p>
          </div>
          <div className="section-header-description">
            <p>Consultez nos guides utilisateurs</p>
            <p>pour notre plateforme</p>
            <p>de Signature Electronique.</p>
          </div>
        </div>

        <div className="mobile-section-header">
          <div className="section-header-title">
            <img className="section-header-logo" src="/Carrejaune.svg" alt="puce"/>
            <p className="section-header-name">Nos guides</p>
          </div>
          <div className="section-header-description">
            <p>Consultez nos guides utilisateurs</p>
            <p>pour notre plateforme</p>
            <p>de Signature Electronique.</p>
          </div>
        </div>
        <div id="ressources">
          {ressourceElement()}
        </div>
      </section>

      <section className="ressources-status">

        <div className="section-header">
          <div className="section-header-title">
            <img className="section-header-logo" src="/Carrebleu.svg" alt="puce"/>
            <p className="section-header-name">Status services</p>
          </div>
          <div style={{flexDirection:"row"}} className="section-header-description">
            <div style={{display:"flex", flexDirection:"column"}}>
              <p>Consultez en temps réel,<br/>l'état de tous nos services.</p>
              <p id="sous-titre-status" className="txt-bleu">Retrouvez l'état de fonctionnement de<br/>l'intégralité de nos services en cliquant <a target="_blank" rel="noreferrer" href="https://stats.uptimerobot.com/2PkkVuKE5E" id="lienstatus">ICI</a></p>
            </div>
            <img id="icone-serveur" src="\pictostatus.svg" alt=""/>
          </div>
        </div>

        <div className="mobile-section-header">
          <div className="section-header-title">
            <img className="section-header-logo" src="/Carrebleu.svg" alt="puce"/>
            <p className="section-header-name">Status services</p>
          </div>
          <div style={{flexDirection:"row"}} className="section-header-description">
            <div style={{display:"flex", flexDirection:"column"}}>
              <p>Consultez en temps réel, l'état de tous nos services.</p>
            </div>
            <img id="icone-serveur" src="\pictostatus.svg" alt=""/>
          </div>
          <p id="sous-titre-status" className="txt-bleu">Retrouvez l'état de fonctionnement de<br/>l'intégralité de nos services en cliquant <a target="_blank" rel="noreferrer" href="https://stats.uptimerobot.com/2PkkVuKE5E" id="lienstatus">ICI</a></p>

        </div>


      </section>

    </div>
  )
}

export default Ressources;

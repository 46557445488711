import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Accueil from "./page/accueil/Accueil";
import Ressources from "./page/ressources/Ressources";
import Actualite from "./page/actualite/Actualite";
import AboutUs from "./page/about-us/AboutUs";
import Affiliation from "./page/affiliations/Affiliation";
import Contact from "./page/contact/Contact";
import Services from "./page/services/Services";
import Prochainement from "./page/prochainement/Prochainement";
import {ObjectContext} from "./context/ObjectContext";
import {useContext} from "react";
import RemerciementContact from "./page/remerciements/remerciement-contact";
import RemerciementAffiliation from "./page/remerciements/remerciement-affiliation";
import RemerciementSignature from "./page/remerciements/remerciement-signature";
import RemerciementService from "./page/remerciements/remerciement-service";
import RNSV from "./page/services/rnsv/rnsv";
import PlateformeDeSignature from "./page/services/plateforme-de-signature/PlateformeDeSignature";
import MentionsLegal from "./components/mentions-legal/mentions-legal";

function App() {

    const footerColor = useContext(ObjectContext).footerColor;

    const appStyle = {
        backgroundColor: footerColor || "#d2fa94"
    }

    return (
      <div style={appStyle} className="app">
          <BrowserRouter>
              <Toaster/>
              <header className="app-header">
                  <Header/>
              </header>

              <div className="app-content">
                  <Routes>
                      <Route path="/" element={<Accueil/>}/>
                      <Route path="/ressources" element={<Ressources/>}/>
                      <Route path="/actualite" element={<Actualite/>}/>
                      <Route path="/a-propos" element={<AboutUs/>}/>
                      <Route path="/affiliation" element={<Affiliation/>}/>
                      <Route path="/contact" element={<Contact/>}/>
                      <Route path="/services" element={<Services/>}/>
                      <Route path="/contact/remerciement" element={<RemerciementContact/>}/>
                      <Route path="/affiliation/remerciement" element={<RemerciementAffiliation/>}/>
                      <Route path="/signature-by-deepblock/remerciement" element={<RemerciementSignature/>}/>
                      <Route path="/services/remerciement" element={<RemerciementService/>}/>
                      <Route path="/services/rnsv" element={<RNSV/>}/>
                      <Route path="/services/plateforme-de-signature/guide" element={<PlateformeDeSignature/>}/>
                      <Route path="/prochainement" element={<Prochainement/>}/>
                      <Route path={"*"} element={<h1>404</h1>}/>
                      <Route path="/mentions-legales" element={<MentionsLegal/>}/>
                  </Routes>
              </div>
              <footer className="app-footer">
                  <Footer/>
              </footer>
          </BrowserRouter>
      </div>
    );
}

export default App;

import "./Actualite.css";
import {useContext, useEffect, useState} from "react";
import {ObjectContext} from "../../context/ObjectContext";
import {getStrapiData} from "../../request/strapi";
import Popup from "../../components/popup/Popup";
import {Helmet} from "react-helmet";

const Actualite = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [clicked, setClicked] = useState([]);
  const [arrayIsLoading, setLoading] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const [mediaLinks, setMediaLinks] = useState([]);

  const setFooterColor = useContext(ObjectContext).setFooterColor;

  useEffect(() => {
    setFooterColor("#e4e3d8")
    fetchActualiteElement();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setClickedArray()
    }
  }, [mediaLinks]);

  const fetchActualiteElement = async () => {
    const mediaLinks = [];
    await getStrapiData('actualite-deepblocks').then((response) => {
      setData(response.data.sort((a, b) => b.id - a.id));
      for (const article of response.data) {
        const mediaLinksTmp = [];
        if (article.attributes.Media.data !== null) {
          for (const media of article.attributes.Media.data) {
            mediaLinksTmp.push(API_URL + media.attributes.url);
          }
        }
        if (article.attributes.Lien_media !== null) {
          for (const mediaUrl of article.attributes.Lien_media.split(' ')) {
            mediaLinksTmp.push(mediaUrl);
          }
        }
        mediaLinksTmp.id = article.id;
        mediaLinks.push(mediaLinksTmp);
      }
      setMediaLinks(mediaLinks);
      setIsLoading(false);
    }).catch((error) => {
      console.error('Erreur lors de la récupération des données:', error);
      setIsLoading(false)
    });
  }

  const setClickedArray = () => {
    const newClicked = [];
    for (let i = 0; i < data.length; i++) {
      if (mediaLinks[i].length > 1) {
        const subClicked = [];
        for (let j = 0; j < mediaLinks[i].length; j++) {
          subClicked.push(false);
        }
        newClicked.push(subClicked);
      } else {
        newClicked.push(false);
      }
    }
    setClicked(newClicked);
    setLoading(false)
  }

  const handleClick = (articleIndex, articleSubIndex = -1) => {
    const newClicked = [...clicked];
    if (articleSubIndex !== -1) {
      newClicked[articleIndex][articleSubIndex] = true;
    } else {
      newClicked[articleIndex] = true;
    }
    setClicked(newClicked);
  };


  // Handling CKEditor content to HTML
  const jsonToHtml = (content) => {
    return content.map((item) => {
      switch (item.type) {
        case "heading":
          return `<h${item.level}>${jsonToHtml(item.children)}</h${item.level}>`;
        case "paragraph":
          return `<p>${jsonToHtml(item.children).join('')}</p>`;
        case "text":
          let text = item.text;
          if (item.bold) text = `<strong>${text}</strong>`;
          if (item.italic) text = `<em>${text}</em>`;
          if (item.underline) text = `<u>${text}</u>`;
          if (item.strikethrough) text = `<s>${text}</s>`;
          if (item.code) text = `<code>${text}</code>`;
          return text;
        case "list":
          // Gère les listes ordonnées et non ordonnées
          const listTag = item.format === 'unordered' ? 'ul' : 'ol';
          return `<${listTag}>${item.children.map(item => {
            if (item.type === 'list-item') {
              return `<li>${jsonToHtml(item.children)}</li>`;
            }
            return '';
          }).join('')}</${listTag}>`;
        case 'link':
          return `<a href="${item.url}" target="_blank">${jsonToHtml(item.children).join('')}</a>`;
        case 'quote':
          return `<blockquote>${jsonToHtml(item.children).join('')}</blockquote>`;
        case 'code':
          return `<pre><code>${jsonToHtml(item.children).join('')}</code></pre>`;
        case 'image':
          const imgUrl = item.image && item.image.url ? item.image.url : '';
          const altText = item.image && item.image.alt ? item.image.alt : 'Image';
          return `<img src="${imgUrl}" alt="${altText}" />`;
        default:
          return null;
      }
    });
  };

  const actualiteElement = () => {
    if (data === undefined) {
      if (isLoading) {
        return <p>Chargement en cours...</p>;
      }
      return <p>Aucune actualité trouvée</p>;
    }

    if (arrayIsLoading) {
      return <p>Chargement en cours...</p>;
    }

    return (
      data.sort((a, b) => b.id - a.id).map((article, articleIndex) => {
        return (
          <div className="article" key={article.id}>
            {
              article.attributes.seo !== undefined && article.attributes.seo !== null ?
                <Helmet>
                  <script type="application/ld+json">
                    {`
                      {
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "headline": "${article.attributes.Titre}",
                        "name": "${article.attributes.seo.metaTitle}",
                        "description": "${article.attributes.seo.metaDescription}",
                        "url": "https://www.deepblock.eu/services?${article.attributes.link}",
                        "image": "${article.attributes.seo.metaImage ? API_URL + article.attributes.seo.metaImage.data.attributes.url : ""}",
                        "datePublished": "${article.attributes.created_at}",
                        "dateModified": "${article.attributes.updated_at}",
                        "author": {
                          "@type": "Organization",
                          "name": "DeepBlock"
                        },
                        "publisher": {
                          "@type": "Organization",
                          "name": "DeepBlock",
                          "logo": {
                            "@type": "ImageObject",
                            "url": "https://www.deepblock.eu/Logo.svg"
                          }
                        },
                      }
                    `}
                  </script>
                  {
                    article.attributes.seo.keywords ?
                      <meta property="keywords" content={article.attributes.seo.keywords ? article.attributes.seo.keywords : ""}/>
                      : null
                  }
                </Helmet> : null
            }
            <div className="mobile-article-text">
              {
                article.attributes.Titre ?
                  <h4 className="article-Titre" dangerouslySetInnerHTML={{__html: jsonToHtml(article.attributes.Titre).join('')}}></h4> :
                  <h4 className="article-Titre">Titre indisponible</h4>
              }
            </div>
            <div className={article.attributes.Lien ? 'a-article' : 'a-article no-cursor'}>
              {mediaElement(article, articleIndex)}
              <div className="article-Text">
                {
                  article.attributes.Titre ?
                    <h4 className="article-Titre" dangerouslySetInnerHTML={{__html: jsonToHtml(article.attributes.Titre).join('')}}></h4> :
                    <h4 className="article-Titre"></h4>
                }
                {
                  article.attributes.Text ?
                    <p className="article-Description" dangerouslySetInnerHTML={{__html: jsonToHtml(article.attributes.Text).join('')}}></p> :
                    <p className="article-Description"></p>
                }
              </div>
            </div>

            <div className="mobile-article-text">
              {
                article.attributes.Text ?
                  <p className="article-Description" dangerouslySetInnerHTML={{__html: jsonToHtml(article.attributes.Text).join('')}}></p> :
                  <p className="article-Description">Description indisponible</p>
              }
            </div>
          </div>
        );
      })
    );
  };

  const mediaElement = (article, articleIndex) => {
    return (
      mediaLinks[articleIndex].length > 1 ? (
        sliderElement(article, articleIndex)
      ) : (
        <a style={article.attributes.Lien ? {cursor: 'pointer'} : {cursor: 'default'}}
           href={article.attributes.Lien || '#'}
           target={article.attributes.Lien ? '_blank' : ''}
           rel="noreferrer"
           onClick={(event) => {
             if (!article.attributes.Lien) {
               event.preventDefault();
             }
           }}>
          {singleMediaElement(article, articleIndex)}
        </a>
      )
    )
  }

  const sliderElement = (article, articleIndex) => {
    return (
      <div className={`slider-container slider-${articleIndex}`} key={"slider-"+articleIndex}>
        {
          mediaLinks[articleIndex].map((mediaUrl, mediaIndex) => {
            if (mediaUrl.includes('youtube.com') || mediaUrl.includes('youtu.be')) {
              const youtubeId = mediaUrl.split('v=')[1] || mediaUrl.split('/').pop();
              {
                return (
                  <div className={"img-slide"}
                       key={articleIndex + "-" + mediaIndex}
                       style={{display: mediaIndex === 0 ? 'block' : 'none'}}>
                    {
                      clicked[articleIndex][mediaIndex] ? (
                        <iframe
                          title={"youtube-video-" + articleIndex + "-" + mediaIndex}
                          key={mediaIndex}
                          src={`https://www.youtube.com/embed/${youtubeId}`}
                          className="youtube-video"
                          allowFullScreen
                          style={{width: "100%", height: "100%", objectFit: "contain"}}
                        />
                      ) : (
                        <div style={{
                          height: "100%",
                          objectFit: "cover",
                          cursor: "pointer",
                          position: "relative",
                          top: "0",
                          left: "0"
                        }}>
                          {
                            article.attributes.Miniature.data !== null ?
                              <img style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                cursor: "pointer",
                                position: "relative",
                                top: "0",
                                left: "0"
                              }}
                                   src={API_URL + article.attributes.Miniature.data.attributes.formats.medium.url}
                                   alt="Miniature vidéo"
                                   onClick={() => handleClick(articleIndex, mediaIndex)}
                              />
                              :
                              <img style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                cursor: "pointer",
                                position: "relative",
                                top: "0",
                                left: "0"
                              }}
                                   src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
                                   alt="Thumbnail of the youtube video"
                                   onClick={() => handleClick(articleIndex, mediaIndex)}
                              />
                          }

                          <img src={"/play-button.png"} className="play-button" alt="Bouton de lecture de la vidéo"
                               onClick={() => handleClick(articleIndex, mediaIndex)}/>
                        </div>
                      )
                    }
                  </div>
                )
              }
            } else if (mediaUrl.match(/\.(jpeg|jpg|gif|png)$/)) {
              return (
                <a style={article.attributes.Lien ? {cursor: 'pointer'} : {cursor: 'default'}}
                   href={article.attributes.Lien || '#'}
                   target={article.attributes.Lien ? '_blank' : ''}
                   rel="noreferrer"
                   onClick={(event) => {
                     if (!article.attributes.Lien) {
                       event.preventDefault();
                     }
                   }}>
                  <img key={mediaIndex} src={mediaUrl} className="img-slide" alt="Indisponible" loading="lazy"
                       style={{display: mediaIndex === 0 ? 'block' : 'none'}}/>
                </a>
              );
            } else if (mediaUrl.match(/\.(mp4|webm|ogg)$/)) {
              return (
                <div key={"video-" + mediaIndex + "-" + articleIndex} className={"img-slide"} style={{display: mediaIndex === 0 ? 'block' : 'none'}}>
                  {
                    (
                      clicked[articleIndex][mediaIndex] ? (
                        <video style={{height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}} src={mediaUrl} controls autoPlay className="article-video"/>
                      ) : (
                        <div style={{height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}>
                          <img style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}
                               src={API_URL + article.attributes.Miniature.data.attributes.formats.medium.url}
                               alt="Miniature vidéo"
                               onClick={() => handleClick(articleIndex, mediaIndex)}/>
                          <img src={"/play-button.png"} className="play-button" alt="Bouton de lecture de la vidéo" onClick={() => handleClick(articleIndex, mediaIndex)}/>
                        </div>
                      )
                    )
                  }
                </div>
              )
            } else {
              return null;
            }
          })
        }
        <button className="slider-button" onClick={(event) => {
          event.preventDefault();
          changeSlide(document.querySelector(`.slider-${articleIndex}`), -1);
        }}>
          <img src="/fleche-gauche.png" alt="Précédent" className="slider-arrow" loading="lazy"/>
        </button>
        <button className="slider-button" onClick={(event) => {
          event.preventDefault();
          changeSlide(document.querySelector(`.slider-${articleIndex}`), 1);
        }}>
          <img src="/fleche-droite.png" alt="Suivant" className="slider-arrow" loading="lazy"/>
        </button>
      </div>
    )
  }

  const singleMediaElement = (article, articleIndex) => {
    return (
      mediaLinks[articleIndex].length === 1 ? (
        <div className={"mobile-adapt"}>
          {
            mediaLinks[articleIndex].map((mediaUrl, mediaIndex) => {
              if (mediaUrl.includes('youtube.com') || mediaUrl.includes('youtu.be')) {
                const youtubeId = mediaUrl.split('v=')[1] || mediaUrl.split('/').pop();
                return (
                  <div className={"article-video youtube-video"} key={"single-media-" + articleIndex + "-" + mediaIndex}>
                    {
                      clicked[articleIndex] ? (
                        <iframe
                          title={"youtube-video-" + articleIndex + "-" + mediaIndex}
                          key={mediaIndex}
                          src={`https://www.youtube.com/embed/${youtubeId}`}
                          className="article-video youtube-video"
                          allowFullScreen
                          style={{width: "100%", height: "100%", objectFit: "cover"}}
                        />
                      ) : (
                        <div style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}>
                          {
                            article.attributes.Miniature.data !== null ?
                              <img style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}
                                   src={API_URL + article.attributes.Miniature.data.attributes.formats.medium.url}
                                   alt="Miniature vidéo"
                                   onClick={() => handleClick(articleIndex)}
                              />
                              :
                              <img style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}
                                   src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
                                   alt="thumbnail of the youtube video"
                                   onClick={() => handleClick(articleIndex, mediaIndex)}
                              />
                          }
                          <img src={"/play-button.png"} className="play-button" alt="Bouton de lecture de la vidéo" onClick={() => handleClick(articleIndex, mediaIndex)}/>
                        </div>
                      )
                    }
                  </div>
                )
              } else if (mediaUrl.match(/\.(jpeg|jpg|gif|png)$/)) {
                return (
                  <img key={"video-hold-" + mediaIndex + "-" + articleIndex} src={mediaUrl} alt="Indisponible" className="article-img" loading="lazy"/>
                );
              } else if (mediaUrl.match(/\.(mp4|webm|ogg)$/)) {
                return (

                  clicked[articleIndex] ? (
                    <video key={"video-show-" + mediaIndex + "-" + articleIndex} src={mediaUrl} controls autoPlay className="article-video"/>
                  ) : (
                    <div key={"img-show-" + mediaIndex + "-" + articleIndex} style={{width: "600px", height: "auto", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}>
                      <img style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}
                           src={API_URL + article.attributes.Miniature.data.attributes.formats.medium.url}
                           alt="Miniature vidéo"
                           onClick={() => handleClick(articleIndex)}
                      />
                      <img src={"/play-button.png"} className="play-button" alt="Bouton de lecture de la vidéo" onClick={() => handleClick(articleIndex, mediaIndex)}/>
                    </div>
                  )
                );
              }
              return null;
            })
          }
        </div>
      ) : null
    )
  }

  const changeSlide = (sliderContainer, direction) => {
    const slides = sliderContainer.getElementsByClassName('img-slide');
    let currentIndex = -1;

    // Trouver l'image actuellement visible
    for (let i = 0; i < slides.length; i++) {
      if (slides[i].style.display === 'block') {
        currentIndex = i;
        break;
      }
    }

    // Cacher l'élément actuel puis afficher le nouveau
    slides[currentIndex].style.display = 'none';
    const newIndex = (currentIndex + direction + slides.length) % slides.length;
    slides[newIndex].style.display = 'block';
  };

  return (
    <div className="actualite-container">

      <Helmet>
        <title>DeepBlock LegalTech - Retrouvez toutes nos actualités au même endroit.</title>
        <meta name="description" content="Le réel bonheur est de faire d'une passion un métier. Retrouvez toutes nos actualités au même endroit."/>
        <meta property="og:title" content="DeepBlock LegalTech - Retrouvez toutes nos actualités au même endroit."/>
        <meta property="og:description" content="Le réel bonnheur est de faire d'une passion un métier. Retrouvez toutes nos actualités au même endroit."/>
        <meta property="og:image" content="https://www.deepblock.eu/Logo.svg"/>
        <meta property="og:url" content="https://www.deepblock.eu/actualite"/>
        <meta property="keywords" content="deepblock, legaltech, génération, conservation, preuves, blockchain, juridique, france, eIDAS, signature, électronique, certificats, qualifiés, entrust, partenariat, union, européenne, actualités, news, articles, blog"/>
        <meta property="author" content="DeepBlock"/>
        <meta property="robots" content="index, follow"/>
      </Helmet>

      <Popup/>

      <section className="actualite-intro">
        <div className="actualite-intro-left">
          <p className="actualite-intro-title">Retrouvez toutes <span className="jaune">nos actualités</span> au même endroit.</p>
          <p className="actualite-intro-description">Le réel bonheur est de faire d'une passion un métier.</p>
        </div>
      </section>

      <section className="actualite-main">
        <div id="articles">
          {actualiteElement()}
        </div>
      </section>
    </div>
  );
}

export default Actualite;
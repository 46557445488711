

const PopupStrapi = ({src = "", href = ""}) => {

  const closePopup = () => {
    localStorage.setItem("popupInterval", Date.now())
    const popup = document.getElementById("cvc-popup")
    popup.style.display = "none"
  }

  return (
    <section className="cvc-popup">
      <div className="cvc-popup-data">
        <img className="cvc-croix" src="/croix.png" alt="close" onClick={closePopup} loading="lazy"/>
        {
          href.length > 0 ?
            <a href={href}>
              <img alt="informative-popup" className="cvc-popup-img" src={src}/>
            </a>
            :
            <img alt="informative-popup" className="cvc-popup-img" src={src}/>
        }
      </div>
    </section>
  )
}

export default PopupStrapi